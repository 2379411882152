<script setup>

import ROUTER from "@/router";
import { ref, computed, onMounted } from "vue";
import firebase from "@/firebase/config";
import { formatDate } from '@/utils/dateHelper.js'
import PrePracticeModal from "../components/PrePracticeModal.vue"

import { ChevronDoubleLeftIcon, CalendarIcon } from "@heroicons/vue/solid";

/**
 * Component for the "This is Beta" announcement.
 */
 import Announcement from "@/modules/caap-101/components/Announcement.vue";
 

const isWaiting = ref(false);
const subtopics = ref([]);
const user = ref(null);
const studySessions = ref([]);
const selectedSubtopic = ref(null);
const currentTip = ref(null);
const isPrePracticeModalOpen = ref(false);
const subjectHasPractice = ref(false);

const TIPS = [
    "The CAAP Knowledge Test are given in various exam sets. Meaning, your exam may differ from what your friend is taking, even if you’re doing the test for the same subject.",
    "CAAP exams have time limits. Typically, the number of items in a subject is also the number of minutes allotted to complete the exam. For example, 30 items means 30 minutes. ",
    "One of the creators of this app has managed to pass all the CAAP Knowledge Test for AMT subjects in only one day. 7 subjects in 1 day. It’s possible.",
    "The passing score for the actual exam is 70% across all subjects. Want to know if you’re ready? Our Mock Exams follows the same passing rates so always give it a try.",
    "Skilltech Reviewer’s concepts and features are the results of in-depth research into real life experiences of Filipino AMT and AVT exam takers. ",
    "This reviewer has been created with the inputs of actual CAAP exam passers. Through hundreds of interviews, surveys, and statistics, we’ve managed to collect the best ideas from the Filipino aviation community. ",
    "In the Philippines, your CAAP Airmen License will be required first before a company promotes you to a Certifying Staff. No license, no promotion. That’s why it’s always better to have it ready.",
    "The CAAP Airmen License is one of the most frequently required credentials when applying for jobs abroad. Job agencies in the Philippines usually include your license in their qualification list.",
    "Our survey shows that the majority of previous exam takers consider Air Law and Airworthiness to be the most difficult subject and Human Performance to be the easiest.",
    "We are BSAMT but we started our OJT right after we got our Associate’s degree. After completing that, we took the CAAP exams. By the time we’re in 3rd year in college, we already have our CAAP AMT licenses.",
]

const subjectId = computed(() => ROUTER.currentRoute.value.query.subjectId);
const subjectName = computed(() => ROUTER.currentRoute.value.query.subjectName);
const sortedSubtopics = computed(() => subtopics.value.sort((a, b) => a.order - b.order));

// const orderToSubtopics = (subjectId) => {

//     var order = []

//     switch (subjectId) {
//         case 'hands-on-performance':
//             order = [
//                 'vernier-caliper-reading',
//                 'micrometer-reading',
//                 'multitester-reading',
//             ]
//             break;
//         case 'explanation-of-process':
//             order = [
//                 'reciprocating-engine-principles',
//                 'turbine-engine-propulsion',
//                 'four-stroke-five-events-cycle',
//             ]
//             break;
//         default:
//             break;
//     }

//     console.log('===>', order);

//     order.forEach((subtopicId, orderIndex) => {
//         let foundIndex = subtopics.value.findIndex(e => e.id == subtopicId)
//         subtopics.value[foundIndex].order = orderIndex
//     });
// }



const closePrePracticeModal = () => {
    isPrePracticeModalOpen.value = false
    
    /**
     * @TODO
     * The values below can't be set to null immediately.
     * This is because we need to account with the closing animation
     * of the dialog box. If the values are nulled while the component
     * is not yet removed, it will throw an error.
     * 
     * A workaround for this, is to always set a new subtopic and
     * new tip every time the user opens the modal. This will overwrite
     * the old values - instead of setting it to null everytime.
     */
    // selectedSubtopic.value = null
    // currentTip.value = null
    
}

const openPrePracticeModal = (subtopic) => {
    isPrePracticeModalOpen.value = true
    selectedSubtopic.value = subtopic
    currentTip.value = TIPS[Math.floor(Math.random() * TIPS.length)]
}

/**
 * Fetch user info from database 
 */
 const fetchUser = () => {
    return new Promise(async (resolve, reject) => {
        let uid = firebase.auth().currentUser.uid;
        firebase.firestore().collection("users").doc(uid).get()
            .then((doc) => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })
    })
}


const fetchSubtopicsBySubject = (subjectId) => {

    return new Promise(async (resolve, reject) => {

        firebase.firestore().collection("reviewers").doc("plus").collection("subtopics").where("subjects", "array-contains", subjectId).get()
        .then((querySnapshot) => {
            var subtopics = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                subtopics.push(data)
            });
            resolve(subtopics)
        })
        .catch((error) => {
            console.error("Error getting subtopic data: ", error);
            reject(error)
        })
       

    })
}

const fetchStudySession = (sId, uId) => {
    return new Promise(async (resolve, reject) => {

        firebase.firestore().collection("plus_study")
        .where('subjectId','==', sId)
        .where('user','==', uId).get()
        .then((querySnapshot) => {
            var sessions = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                sessions.push(data)
            });
            resolve(sessions)
        })
        .catch((error) => {
            console.error("Error getting sessions data: ", error);
            reject(error)
        })


    })
}


const fecthSubtopicsQuestionItems = () => {

    return new Promise(async (resolve, reject) => {

        for (let index = 0; index < subtopics.value.length; index++) {
            const subtopic = subtopics.value[index];
            
            let _questionItemsCount = 0;

            try {
                const doc = await firebase.firestore().collection("questionnaires").doc(subtopic.id).get();

                // check if doc exist
                if (doc.exists) {
                    
                    let data = doc.data();
                    // Loop through each item in the questions array and check if it contains a question or if it is just a study-only item.
                    data.questions.forEach((q)=>{
                        if (q.question.trim() != '' || (q.questionHtml.trim() != '' && q.questionHtml.trim() != '<p><br></p>' && q.questionHtml.trim() !== '<p></p>')) {
                            _questionItemsCount++;
                        }
                    })
                }
                
            }catch(e){
                console.log('Not question for this subtopic ID.')
            }

            subtopic.questionItemsCount = _questionItemsCount;

        }

        console.log('completed!')
        resolve(true)

    })
}

/**
 * Get all Subtopics Practice Data
 * Check if a subtopic is a Key subtopic
 * Check if a subtopic has been completed
 * Check if a subtopic has a paused practice exam
 */
const getSubtopicsPracticeData = (uid) => {
  return new Promise(async (resolve, reject) => {
    subtopics.value.forEach( async (subtopic, index) => {
      await firebase.firestore().collection("plus_practice")
        .where("user", "==", uid)
        .where("subtopic", "==", subtopic.id)
        .where("status", "in", ["started","completed"])
        .get()
        .then((querySnapshot) => {

          var subtopics_data = [];
          if(querySnapshot.size > 0){

            // this subtopic already has practices that are started.
            subtopics.value[index].isStarted = true
            
            querySnapshot.forEach( doc => {
              // console.log(doc.data());
              let data = doc.data()
              data.id = doc.id
              subtopics_data.push(data)
            })

            // Check if this subtopic is already completed. 
            // If there are any existing practice that is "status==completed",
            // this means the user already went through it.
            var subtopic_practices_completed = subtopics_data.filter( e => e.status == "completed")
            subtopics.value[index].isCompleted = (subtopic_practices_completed.length > 0) ? true : false

            // Even if a subtopic practice is completed, 
            // a user can still start a practice session
            // and there might be open practices (i.e. "status==started") 
            // which is in progress. Fetch them so user can allow to continue
            var subtopic_practices_started = subtopics_data.filter( e => e.status == "started")
            subtopics.value[index].isInProgress = (subtopic_practices_started.length > 0) ? true : false

            // If there's a paused exam, store it for reference later
            var most_recent_practice = null;
            if(subtopic_practices_started.length > 0){
              // Reduce the array to the most recently taken exam
              most_recent_practice = subtopic_practices_started.reduce( (previousValue, currentValue) => {
                return (currentValue.time.start > previousValue.time.start) ? currentValue : previousValue
              });
            }
            subtopics.value[index].pausedPractice = most_recent_practice // will return null if none

          }else{
            subtopics.value[index].isStarted = false
          }

          resolve(true)
          
        })
        .catch((error) => {
          console.error("Error getting document => ", error);
          reject(error)
        })

    });
  })
}


const getStudyProgress = (subtId) => {
    return studySessions.value.filter((session) => session.subtopicId == subtId)
}

const handleStudyClick = (subtopic) => {
    ROUTER.push({ path: `/plus/study`, query: { subtopicId: subtopic.id, subtopicName: subtopic.name, subjectId: subjectId.value, subjectName: subjectName.value } })
}

onMounted(async () => {

    isWaiting.value = true

    user.value = await fetchUser();

    if (subjectId.value) {

        // fetch subtopic and study session for this subject
        [subtopics.value, studySessions.value] = await Promise.all([
            fetchSubtopicsBySubject(subjectId.value),
            fetchStudySession(subjectId.value, user.value.id),
        ]);

    
        Promise.all([
            getSubtopicsPracticeData(user.value.id),
            fecthSubtopicsQuestionItems()
        ])

        if (subtopics.value.length > 0) {
            // orderToSubtopics(subjectId.value)

            // check if subject has practice
            var subjectWithPractice = [
                'identification-general',
                'identification-aircraft'
            ];

            subjectHasPractice.value = subjectWithPractice.includes(subjectId.value);


        } else {
            console.error("No results found. Please make sure you have correct Subject Id.")
        }

        
        isWaiting.value = false

    } else {
        console.error("There is no subject being passed. You will be redirected")
        isWaiting.value = false
    }


});

</script>
<template>
    <div class="mx-auto py-2">
        <div class="space-y-6 mx-0 lg:mx-6">
            <Announcement />
            <nav class="flex justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                    <router-link to="/plus/subjects"
                        class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center"
                        aria-current="page">
                        <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                    </router-link>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span class="hidden md:block">{{ subjectName }}</span>
                    <span class="block md:hidden">{{ subjectName }}</span>
                </div>
                <div class="w-6 h-6"></div>
            </nav>
            <div v-if="!isWaiting" class="gap-6 grid grid-cols-1 lg:grid-cols-2">

                <div v-for="subtopic in sortedSubtopics" :key="subtopic.id"
                    class="text-base flex flex-col rounded-2xl border border-true-gray-700 dark:bg-true-gray-800 dark:bg-opacity-50 bg-white border-opacity-50">
                    <div class="grid grid-cols-1 gap-4 p-6 flex-1">
                        <div class="focus:outline-none flex flex-col justify-between">
                            <div>
                                <div class="text-base font-medium text-warm-gray-400 mb-4">
                                    <div class="flex justify-between items-center">
                                        <!-- <CheckCircleIcon class="text-warm-gray-600 w-6 h-6 mr-1 flex-shrink-0" /> -->
                                        <h1 class="text-xl font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                            {{ subtopic.name }}
                                        </h1>
                                    </div>
                                </div>
                                <p class="text-sm text-warm-gray-500 mt-2">{{ subtopic.description }}</p>
                            </div>

                            <!-- Practice progress -->
                            <!-- <div v-if="subjectHasPractice" class="mt-4 pt-2 border-t border-warm-gray-600 border-opacity-25">
                                <div v-if="subtopic.isCompleted && !subtopic.isInProgress"
                                class="relative pt-1">
                                    <div class="flex mb-2 items-center justify-between">
                                        <div>
                                            <span
                                                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                                                Practice Completed
                                            </span>
                                        </div>
                                        <div class="text-right">
                                            <span class="text-xs font-semibold inline-block text-green-600">
                                                100%
                                            </span>
                                        </div>
                                    </div>
                                    <div class="overflow-hidden h-2 text-xs flex rounded bg-green-200">
                                        <div style="width:100%"
                                            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500">
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="subtopic.pausedPractice && subtopic.pausedPractice.progress < 100"
                                    class="relative pt-1">
                                    <div class="flex mb-2 items-center justify-between">
                                        <div>
                                            <span
                                                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200">
                                                Practice In progress
                                            </span>
                                        </div>
                                        <div class="text-right">
                                            <span class="text-xs font-semibold inline-block text-indigo-600">
                                                {{ subtopic.pausedPractice.progress }}%
                                            </span>
                                        </div>
                                    </div>
                                    <div class="overflow-hidden h-2 text-xs flex rounded bg-indigo-200">
                                        <div :style="`width:${subtopic.pausedPractice && parseFloat(subtopic.pausedPractice.progress)}%`"
                                            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500">
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="relative pt-1">
                                    <div class="flex mb-2 items-center justify-between">
                                        <div>
                                            <span
                                                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-warm-gray-600 bg-warm-gray-200 dark:text-warm-gray-400 dark:bg-true-gray-700">
                                                Practice Not Yet Started
                                            </span>
                                        </div>
                                        <div class="text-right">
                                            <span
                                                class="text-xs font-semibold inline-block text-warm-gray-600 dark:text-true-gray-400">
                                                0%
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        class="overflow-hidden h-2 text-xs flex rounded dark:bg-true-gray-700 bg-warm-gray-200">
                                        <div style="width:0%"
                                            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center dark:bg-true-gray-700 bg-gray-500">
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <!-- Study progress -->
                            <div class="pt-2">
                                <div v-if="getStudyProgress(subtopic.id)[0] && getStudyProgress(subtopic.id)[0].isComplete == true"
                                class="relative pt-1">
                                    <div class="flex mb-2 items-center justify-between">
                                        <div>
                                            <span
                                                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                                                Study Completed
                                            </span>
                                        </div>
                                        <div class="text-right">
                                            <span class="text-xs font-semibold inline-block text-green-600">
                                                100%
                                            </span>
                                        </div>
                                    </div>
                                    <div class="overflow-hidden h-2 text-xs flex rounded bg-green-200">
                                        <div style="width:100%"
                                            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500">
                                        </div>
                                    </div>
                                </div>

                                <div v-else-if="getStudyProgress(subtopic.id)[0] && getStudyProgress(subtopic.id)[0].isComplete == false"
                                    class="relative pt-1">
                                    <div class="flex mb-2 items-center justify-between">
                                        <div>
                                            <span
                                                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200">
                                                {{ getStudyProgress(subtopic.id)[0].isComplete == false ? 'Study In progress' :
                            'Study Completed' }}
                                            </span>
                                        </div>
                                        <div class="text-right">
                                            <span class="text-xs font-semibold inline-block text-indigo-600">
                                                {{ getStudyProgress(subtopic.id)[0] && (
                            getStudyProgress(subtopic.id)[0].progress == 1 ? 99 :
                                (getStudyProgress(subtopic.id)[0].progress * 100).toFixed(2)) }}%
                                            </span>
                                        </div>
                                    </div>
                                    <div class="overflow-hidden h-2 text-xs flex rounded bg-indigo-200">
                                        <div :style="`width:${getStudyProgress(subtopic.id)[0] && (getStudyProgress(subtopic.id)[0].progress == 1 ? 99 : getStudyProgress(subtopic.id)[0].progress * 100)}%`"
                                            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500">
                                        </div>
                                    </div>
                                </div>

                                <div v-else class="relative pt-1">
                                    <div class="flex mb-2 items-center justify-between">
                                        <div>
                                            <span
                                                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-warm-gray-600 bg-warm-gray-200 dark:text-warm-gray-400 dark:bg-true-gray-700">
                                                Study Not Yet Started
                                            </span>
                                        </div>
                                        <div class="text-right">
                                            <span
                                                class="text-xs font-semibold inline-block text-warm-gray-600 dark:text-true-gray-400">
                                                0%
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        class="overflow-hidden h-2 text-xs flex rounded dark:bg-true-gray-700 bg-warm-gray-200">
                                        <div style="width:0%"
                                            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center dark:bg-true-gray-700 bg-gray-500">
                                        </div>
                                    </div>
                                </div>

                                <div v-if="getStudyProgress(subtopic.id)[0] && getStudyProgress(subtopic.id)[0].isComplete == false" class=" flex justify-start items-center text-warm-gray-600 text-xs mt-2">
                                    <CalendarIcon class=" w-4 h-4 mr-1" /> <span>Last Read: {{
                                        getStudyProgress(subtopic.id)[0] &&
                                        formatDate(getStudyProgress(subtopic.id)[0].lastDateStudy.seconds) }}</span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="dark:bg-true-gray-700 dark:bg-opacity-25 bg-gray-50 min-w-0 px-6 py-3 rounded-b-2xl">
                        <div class="text-sm flex justify-between">
                            <button @click="handleStudyClick(subtopic)"
                                class="font-medium dark:text-yellow-400 dark:hover:text-yellow-300 text-yellow-600 hover:text-yellow-700">
                                Start Reading
                            </button>

                            <button v-if="subjectHasPractice" @click="openPrePracticeModal(subtopic, subjectId, subjectName)"
                                class="font-medium dark:text-indigo-400 dark:hover:text-indigo-300 text-indigo-700 hover:text-indigo-900">
                                Start Practice
                            </button>
                            <!-- <div class=" text-warm-gray-400">
                                {{ subtopic.questionItemsCount }} Items
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class=" gap-6 grid grid-cols-1 lg:grid-cols-2">
                <div v-for="i in 4" :key="i" class="relative animate-pulse rounded-lg overflow-hidden dark:bg-true-gray-600 dark:bg-opacity-50 bg-white shadow-sm dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
                    <div class="flex-1 min-w-0 col-span-1 p-6">
                        
                        <div class="focus:outline-none text-left">
                            <div class="flex dark:text-warm-gray-400 text-warm-gray-800 items-center">
                                <div class=" bg-warm-gray-300 dark:bg-warm-gray-500 w-7 h-7 rounded-md opacity-60"></div>
                                <div class=" w-32 h-6 rounded-md bg-warm-gray-300 dark:bg-warm-gray-500 ml-1 opacity-60"></div>

                            </div>
                            <div class=" rounded-md opacity-60 bg-warm-gray-300 dark:bg-warm-gray-500 w-full h-4 mt-2"></div>
                            <div class=" rounded-md opacity-60 bg-warm-gray-300 dark:bg-warm-gray-500 w-full h-4 mt-1"></div>
                            <div class=" rounded-md opacity-60 bg-warm-gray-300 dark:bg-warm-gray-500 w-3/4 h-4 mt-1"></div>
                        </div>

                        <div class="relative pt-1 mt-4">
                            <div class="flex mb-2 items-center justify-between">
                                <div class="rounded-md opacity-60 bg-warm-gray-300 dark:bg-warm-gray-500 h-4 w-24"> </div>
                                <div class="rounded-md opacity-60 bg-warm-gray-300 dark:bg-warm-gray-500 h-4 w-6"></div>
                            </div>
                            <div class="overflow-hidden h-2 text-xs flex rounded dark:bg-true-gray-700 bg-warm-gray-200"></div>
                        </div>
                    </div>
                    <div class="dark:bg-true-gray-400 dark:bg-opacity-25 bg-gray-50 flex-1 min-w-0 px-6 py-5"></div>
                </div>
            </div>
        </div>
    </div>

    <PrePracticeModal :isOpen="isPrePracticeModalOpen" :subtopic="selectedSubtopic" :subjectId="subjectId" :subjectName="subjectName" :tip="currentTip" @closeModal="closePrePracticeModal()" />

</template>