<script setup>
import {ref} from 'vue';
import { ArrowLeftIcon, OfficeBuildingIcon } from '@heroicons/vue/outline';

const sites = ['PATTS','OMNI','PHILSCA','FEATI','Others'];


</script>
<template>
    <div class=" text-warm-gray-600 dark:text-warm-gray-400 flex justify-center items-start">

        <div class="w-full lg:w-5/12">

            <div class=" flex justify-between items-center">
                <router-link to="/plus" class="flex justify-center items-center text-sm gap-1 text-warm-gray-500">
                    <ArrowLeftIcon class="w-5 h-4"/>
                    <span class="whitespace-nowrap">
                        Go Back
                    </span>
                </router-link>
            </div>

            <div class=" mt-6">
                <h2 class=" text-2xl font-semibold tracking-wide text-warm-gray-200">
                    CAAP Practical Exam Survey
                </h2>
            </div>
    
            <div>
    
                <!-- Page 1 -->
                <div>
    
                    <div class=" flex flex-col mt-8 space-y-2">
                        <label for="date">Date of Skill Test</label>
                        <input type="date" name="date" id="date" class=" text-warm-gray-600 rounded-md p-4">
                    </div>

                    <div class="mt-8">
                        <label>Skill Test Site</label>
                        <div class=" space-y-4 mt-2">
                            <button v-for="site in sites" class=" w-full p-4 border-2 rounded-md text-left flex justify-start items-center bg-blue-200 text-blue-800 border-blue-300 hover:border-blue-600 hover:bg-blue-300 dark:font-semibold">
                                <OfficeBuildingIcon class=" w-5 h-5 mr-2"/>
                                {{ site }}
                            </button>
                        </div>
                    </div>
    
                </div>
    
            </div>

        </div>


    </div>
</template>